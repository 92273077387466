<template>
  <div class="nav container container--fluid"
       :class="{ 'nav--sticky': sticky }"
  >
    <div class="nav__main">
      <router-link :to="logoLink"
                   class="nav__logo"
                   data-test-id="navbar-logo"
      >
        <img v-if="showLogo"
             alt="Bistroo"
             src="/assets/img/brand/bistroo-logo.svg"
             width="126"
             data-not-lazy
        >
      </router-link>
      <div class="nav__menu">
        <ul class="nav__menu-items">
          <li class="nav__menu-item"
              data-test-id="user-account-button-mobile"
          >
            <ClientOnly>
              <!-- Mobile user button -->
              <UserAccountButtonContainer inline />
            </ClientOnly>
          </li>

          <!-- Mobile checkout button -->
          <li v-if="!$route.name || !$route.name.includes('checkout')"
              v-taptic="'selection'"
              class="nav__menu-item"
              data-test-id="checkout-button-mobile"
              @click="$router.push(localizedRoute({ name: 'checkout' }))"
          >
            <BIcon name="cart" />
          </li>
          <li v-show="showFiltersButton"
              v-taptic="'selection'"
              class="nav__menu-item"
              @click="$store.dispatch('session/setFiltersTooltipOpen', true)"
          >
            <BIcon class="nav__filters"
                   :name="hasActiveFilters ? 'filter-active' : 'filter'"
            />
          </li>
          <li class="nav__menu-item" data-test-id="mobile-menu">
            <Tooltip>
              <template #default="{ active }">
                <BIcon :name="active ? 'cross' : 'menu'" />
              </template>
              <template #content>
                <MenuTooltip />
              </template>
            </Tooltip>
          </li>
        </ul>
      </div>
    </div>

    <!-- Toolbar when scrolling back up -->
    <div v-if="open"
         class="nav__options"
         data-test-id="navbar-mobile-options"
    >
      <div class="nav__divider">
        <Divider type="dashed" />
      </div>
      <MobileOptions :has-active-filters="hasActiveFilters"
                     @clicked-address="openAddressModal"
                     @clicked-distribution-type="toggleDistribution"
                     @clicked-filters="openFiltersTooltip">
        <template #address-value>
          {{ address }}
        </template>
        <template #dist-value>
          {{ distribution }}
        </template>
      </MobileOptions>
    </div>

    <!-- border bottom line -->
    <div class="nav__border-bottom" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ClientOnly } from 'vite-plugin-vue-ssr'
import UserAccountButtonContainer from '@/components/Buttons/UserAccountButton/UserAccountButtonContainer.vue'
import MobileOptions from '@/components/Navigation/MobileOptions.vue'
import Divider from '@/components/partials/Divider.vue'
import BIcon from '@/components/partials/Icon.vue'
import MenuTooltip from '@/components/Menu/MenuTooltip.vue'
import Tooltip from '@/components/partials/Tooltip.vue'
import { useResizeObserver } from '@/composables/resizeObserver'
import { useLocalizedRouter } from '@/composables/localizedRouter'

export default {
  name: 'NavBarMobile',

  components: {
    UserAccountButtonContainer,
    MobileOptions,
    MenuTooltip,
    BIcon,
    Tooltip,
    Divider,
    ClientOnly,
  },

  props: {
    borderBottom: { type: Boolean, default: false, },
    logoLink: { type: Object, default: null, },
  },

  setup() {
    const { val, } = useResizeObserver()
    const { localizedRoute, } = useLocalizedRouter()

    return {
      val,
      localizedRoute,
    }
  },

  data() {
    return {
      scrollState: 0,
      isPastHeader: false,
      showFiltersButton: false,
      hasActiveFiltersOnReload: false,
      open: false,
      sticky: false,
    }
  },

  computed: {
    ...mapGetters({
      filtersTooltipOpen: 'session/filtersTooltipOpen',
      hasActiveFilters: 'session/hasActiveFilters',
      location: 'session/location',
      hasLocation: 'session/hasLocation',
      distributionType: 'session/distributionType',
    }),

    showLogo() {
      return (
        !__CAPACITOR__ ||
        this.$route.name?.includes('slug') ||
        this.$route.name?.includes('marketplace') ||
        this.$route.name?.includes('customer') ||
        this.$route.name?.includes('verified')
      )
    },

    isOnAllowedPageForMobileOptions() {
      const excludedPagePatterns = ['search',]

      return ['marketplace', 'slug',].some(
        (pattern) =>
          // check if the route name includes the allowedPattern
          this.$route.name?.includes(pattern) &&
          !excludedPagePatterns.some((excluded) =>
            this.$route.name?.includes(excluded)
          )
      )
    },

    address() {
      if (!this.hasLocation) {
        return `${this.t('terms.loading')}...`
      }

      const location = this.location

      return location.street || location.city
    },

    distribution() {
      if (this.distributionType === DistributionTypes.Delivery) {
        return this.t('terms.delivery')
      }

      return this.t('distribution-toggle.takeaway')
    },
  },

  watch: {
    filtersTooltipOpen(open) {
      if (!this.$route.name?.includes('marketplace-search') && open)
        this.displayFiltersIconInMenu()

      if (open) {
        this.open = false
        this.showFiltersButton = this.isPastHeader
      }
    },

    $route() {
      if (this.$route.name?.includes('marketplace-search'))
        this.displayFiltersIconInMenu()
    },
  },

  beforeUnmount() {
    // try to remove the evt listener in any case
    window.removeEventListener('scroll', this.scrollDetect)
  },

  mounted() {
    // only trigger on reload / refresh
    if (this.hasActiveFilters) {
      this.$refs['filters-tooltip']?.open()
      this.hasActiveFiltersOnReload = true
    }

    window.addEventListener('scroll', this.scrollDetect)
    window.addEventListener('resize', () => {
      this.open = false
    })

    if (this.$route.name?.includes('search')) {
      this.open = false
      this.showFiltersButton = true
    }
  },

  methods: {
    displayFiltersIconInMenu() {
      this.open = false
      this.showFiltersButton = true
    },

    scrollDetect() {
      // if the tooltip is open, short out
      if (this.filtersTooltipOpen) return

      // on desktop devices, never show the navbar options
      // enable only on whitelisted pages
      if (this.val >= 992 || !this.isOnAllowedPageForMobileOptions) {
        this.open = false
        return
      }

      // Current scroll position
      const top = window.scrollY

      const rootFontSize = parseInt(
        window
          .getComputedStyle(document.body)
          .getPropertyValue('font-size')
          .slice(0, -2)
      )

      this.isPastHeader = top > rootFontSize * 3.5
      this.isBeforeHeader = top <= rootFontSize * 3.5

      console.log(this.isBeforeHeader)

      if (this.isBeforeHeader) {
        // up or above header
        this.showFiltersButton = false
        this.open = false
      } else if (top > this.scrollState) {
        // down
        this.open = false
        this.showFiltersButton = true
      } else {
        // up
        this.open = true
        this.showFiltersButton = false
      }

      // Set previous scroll position
      this.scrollState = top

      this.sticky = this.scrollState > 0
    },

    openAddressModal() {

    },

    toggleDistribution() {
      const newDistType =
        this.$store.getters['session/distributionType'] ===
        DistributionTypes.TakeAway
          ? DistributionTypes.Delivery
          : DistributionTypes.TakeAway

      this.$store.dispatch('session/storeDistributionType', newDistType)
    },

    openFiltersTooltip() {
      this.$store.dispatch('session/setFiltersTooltipOpen', true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.nav {
  display: block;
  width: 100%;
  position: relative;
  padding: 0 1.5rem;

  @include lg-up {
    padding: 0;
    display: none;
  }

  &__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: calc(1.125rem + env(safe-area-inset-top)) 0 1.125rem;

    @include lg-up {
      padding: .5rem 4rem;
    }
  }

  &__logo {
    @include lg-down {
      height: 2rem;

      img {
        height: 100%;
        width: auto;
        user-select: none;
        transform: translateY(2%);
        cursor: pointer;
      }
    }
  }

  &__menu {
    display: flex;
    align-content: center;

    @include lg-up {
      display: none;
    }
  }

  &__menu-items {
    display: flex;
    margin: 0 -0.75rem;
    list-style: none;
    padding: 0;
    cursor:pointer;
  }

  &__menu-item {
    display: flex;
    justify-content: center;
    margin: 0 0.75rem;
  }

  &__filters {
    transform: scale(0.9);
  }

  &__options {
    padding-bottom: 1rem;

    @include lg-up {
      display: none;
    }
  }

  &__divider {
    margin-bottom: 1rem;
  }

  &__border-bottom {
    border-bottom: 1px solid rgba(36, 46, 74, 0.15);

    @include lg-up {
      display: none;
    }
  }

  &--sticky {
    @include shadow-md;
  }

  &:deep(.user-account-button span),
  &:deep(.tooltip),
  &:deep(.tooltip__element) {
    display: flex;
    justify-content: center;
  }
}
</style>
